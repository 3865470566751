//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Gauge, Line } from "@antv/g2plot";
export default {
  name: "TaskData",
  props: {
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTaskTab: "总",
      taskTabs: ["今", "总"],
      chartToday: null,
      chartTotal: null,
    };
  },
  watch: {
    taskData(val) {
      if (this.taskData) {
        this.$nextTick(() => {
          this.chartToday.changeData(this.todayPercent);
          if (this.totalData) {
            this.chartTotal.changeData(this.totalData);
          }
        });
      }
    },
  },
  computed: {
    todayPercent() {
      var percent = 0;
      if (
        this.taskData &&
        this.taskData.Today &&
        this.taskData.Today.Total > 0
      ) {
        percent = this.taskData.Today.Complete / this.taskData.Today.Total;
        percent = percent.toFixed(2);
      }
      return percent;
    },
    totalData() {
      var data = [];
      if (this.taskData && this.taskData.Total) {
        var totalData = this.taskData.Total.map((m) => {
          return {
            Type: "任务总数量",
            Time: m.Time.timeFormat("yyyy-MM-dd"),
            Count: m.Total,
          };
        });
        data.push(...totalData);
        var completeData = this.taskData.Total.map((m) => {
          return {
            Type: "已完成数量",
            Time: m.Time.timeFormat("yyyy-MM-dd"),
            Count: m.Complete,
          };
        });
        data.push(...completeData);
      }
      return data;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.activeTaskTab = tab;
    },
    renderChartToday() {
      if (this.chartToday) {
        return;
      }
      this.chartToday = new Gauge(this.$refs["chart-today"], {
        percent: this.todayPercent,
        range: {
          color: ["l(0) 0:#EB7E65 1:#F5A636", "#FAD2C9"],
          width: 25,
        },
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        statistic: {
          title: {
            offsetY: -10,
            style: {
              fontSize: "32px",
              color: "#4B535E",
              fontWeight: "bold",
              textAlign: "center",
            },
            formatter: () => {
              var text = this.todayPercent * 100 + "%";
              return text;
            },
          },
          content: {
            offsetY: -45,
            style: {
              fontSize: "12px",
              lineHeight: "12px",
              color: "#4B535E",
              textAlign: "center",
            },
            formatter: () => "进度",
          },
        },
      });

      this.chartToday.render();
    },
    renderChartTotal() {
      if (this.chartTotal) {
        return;
      }
      this.chartTotal = new Line(this.$refs["chart-total"], {
        data: this.totalData,
        xField: "Time",
        yField: "Count",
        seriesField: "Type",
        smooth: true,
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: "#FFFFFF",
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
          nice: true,
        },
        point: {
          size: 4,
          shape: "square",
          style: {
            lineWidth: 2,
          },
        },
        legend: {
          layout: "verticle",
          position: "left",
        },
        slider: {
          start: 0,
          end: 0.5,
        },
        meta: {
          Time: {
            alias: "时间",
          },
          Count: {
            alias: "数量",
          },
        },
      });

      this.chartTotal.render();
    },
  },
  mounted() {
    this.renderChartToday();
    this.renderChartTotal();
  },
};
